<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="模块开关">
        <el-radio-group v-model="form.value" size="small">
          <el-radio-button label="0">暂不启用</el-radio-button>
          <el-radio-button label="1">启用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="触发时机" prop="">
        <el-radio-group v-model="form.value_conf.trigger_type" size="small">
          <el-radio-button label="0">下单付款时</el-radio-button>
          <el-radio-button label="1">已收货时</el-radio-button>
          <el-radio-button label="2">订单完成时</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="参与会员" prop="value_conf.levels">
        <el-checkbox-group v-model="form.value_conf.levels">
          <el-checkbox v-for="item in levelArr" :key="item.id" :label="item.id">{{
              item.name
            }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="奖励内容" prop="value_conf.award">
        <el-checkbox-group v-model="form.value_conf.award" size="small">
          <el-checkbox label="0" @change="getCheckValue1">优惠券</el-checkbox>
          <el-checkbox label="1" @change="getCheckValue2">积分</el-checkbox>
          <el-checkbox label="2" @change="getCheckValue3">账户余额</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-show="type1" style="margin-bottom: 20px" label="优惠券" prop="">
        <el-select
            v-model="couponObj"
            clearable
            multiple
            placeholder="请选择"
            style="width: 200px"
            size="small"
            @change="getCouponNum"
            @focus="monitoringCoupon"
        >
          <el-option
              v-for="item in couponArr"
              :key="item.id"
              :disabled="couponType"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
        <el-button size="small" type="primary" style="margin-left: 10px" @click="addCoupons">添加优惠券(最多三张)</el-button>
        <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px" height="300">
          <el-table-column
              align="center"
              prop="name"
              label="优惠券名">
          </el-table-column>
          <el-table-column
              width="120"
              align="center"
              prop="quantity"
              label="现有余量">
          </el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item v-show="type2" style="margin-bottom: 20px" label="返积分比例" prop="">
        <el-tooltip class="item" effect="dark" content="比例必须为整数，实际返积分=实际消费金额X返回比例" placement="top">
          <el-input-number
              v-model="form.value_conf.integral_proportion"
              :step="1"
              step-strictly
              :min="0"
              :max="100"
              controls-position="right"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item v-show="type3" style="margin-bottom: 20px" label="返现金金比例" prop="">
        <el-tooltip placement="top">
          <div slot="content">比例必须为整数，返回的现金存进个人账户<br/>的赠送额度，不可提现，只能用于消费</div>
          <el-input-number
              v-model="form.value_conf.cash_proportion"
              :step="1"
              step-strictly
              :min="0"
              :max="100"
              controls-position="right"
              size="small"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="简单说明" prop="value_conf.details">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.value_conf.details"
            style="width: 25%"
            placeholder="请输入说明"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import {
  apiGetConsumerInfo,
  apiGetNotPageCoupon,
  apiGetUserLevelList,
  apiSetConsumerIncentives,
} from "@/request/api";

export default {
  components: {},
  props: [],
  data() {
    return {
      couponType: false,
      type1: false,
      type2: false,
      type3: false,
      couponObj: [],
      couponArr: [],
      list_state: false,
      list: [],
      levelArr: [],
      form: {
        value: '0',
        value_conf: {
          trigger_type: '0',
          levels: [],
          award: [],
          coupon: [],
          integral_proportion: '',
          cash_proportion: '',
          details: '',
        }
      },
      rules: {
        value_conf: {
          levels: [
            {required: true, trigger: 'blur', message: '会员不能为空'},
          ],
          award: [
            {required: true, trigger: 'blur', message: '奖励类型不能为空'},
          ],
          details: [
            {required: true, trigger: 'blur', message: '说明不能为空'},
          ],
        },
      },
    }
  },
  created() {
    this.getLevelList()
    this.getCouponList()
    this.fetchData()
  },
  methods: {
    //监听选中优惠券数量
    monitoringCoupon() {
      if (this.list.length == 3) {
        this.couponType = true
        this.$message.error('最多只能选择三张优惠券')
      } else {
        this.couponType = false
      }
    },
    getCouponNum(e) {
      if (e.length + this.list.length == 3) {
        // this.$message.success('')
        this.couponType = true
      } else {
        this.couponType = false
      }
    },
    //监听奖励内容选择
    getCheckValue1(e) {
      this.type1 = e
    },
    getCheckValue2(e) {
      this.type2 = e
    },
    getCheckValue3(e) {
      this.type3 = e
    },
    //获取用户等级列表(不分页)
    getLevelList() {
      apiGetUserLevelList().then(res => {
        if (res.code == 200) {
          this.levelArr = res.data
        }
      })
    },
    //获取优惠券列表-不分页
    getCouponList() {
      apiGetNotPageCoupon().then(res => {
        if (res.code == 200) {
          this.couponArr = res.data
        }
      })
    },
    //添加优惠券
    addCoupons() {
      this.couponObj.forEach(item => {
        this.couponArr.forEach(child => {
          if (item == child.id) {
            this.list.push(child)
          }
        })
      })
      const map = new Map()
      // 对id属性进行去重
      this.list = this.list.filter(key => !map.has(key.id) && map.set(key.id, 1))
      if (this.list.length < 3) {
        this.couponType = false
      }
      this.couponObj = []
    },
    handleDelete(row) {
      console.log(row)
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.list.map((val, i) => {
            if (val.id == row.id) {
              this.list.splice(i, 1)
            }
          })
          this.couponType = false
        })
      }
    },
    //获取消费奖励详情
    async fetchData() {
      this.listLoading = true
      apiGetConsumerInfo(this.queryForm).then(res => {
        if (res.code = 200) {
          const tempObj = res.data
          console.log(res.data)
          this.form = {
            value: tempObj.value,
            value_conf: {
              trigger_type: tempObj.value_conf.trigger_type,
              levels: tempObj.value_conf.levels,
              award: tempObj.value_conf.award,
              coupon: this.getCoupons(tempObj.value_conf.coupon),
              integral_proportion: tempObj.value_conf.integral_proportion,
              cash_proportion: tempObj.value_conf.cash_proportion,
              details: tempObj.value_conf.details,
            }
          }
          tempObj.value_conf.award.forEach(item => {
            if (item == 1) {
              this.type2 = true
            } else if (item == 2) {
              this.type3 = true
            } else if (item == '0') {
              this.type1 = true
            }
          })
          this.list = tempObj.value_conf.coupon
        }
      })
      this.listLoading = false
    },
    getCoupons(arr) {
      const tempArr = []
      arr.forEach(item => {
        tempArr.push(item.id)
      })
      return tempArr
    },
    //提交
    submit() {
      const index0 = this.form.value_conf.award.indexOf('0')
      const index1 = this.form.value_conf.award.indexOf('1')
      const index2 = this.form.value_conf.award.indexOf('2')
      this.list = index0 != -1 ? this.list : []
      this.form.value_conf.integral_proportion = index1 != -1 ? this.form.value_conf.integral_proportion : ''
      this.form.value_conf.cash_proportion = index2 != -1 ? this.form.value_conf.cash_proportion : ''
      this.form.value_conf.coupon = []
      if (this.list.length > 0) {
        this.list.forEach(item => {
          this.form.value_conf.coupon.push(item.id)
        })
      } else {
        this.$message.error('优惠券不能为空')
        return
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiSetConsumerIncentives(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.list = []
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
  }
}

</script>
